import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, of, pipe } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService, UserDto, UserService } from '../api';

const loginUrl = `${environment.api}/api/Auth/Login`;
const logoutUrl = `${environment.api}/api/Auth/Logout`;

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private _currentUser: UserDto | undefined;

    constructor(
        private router: Router,
        private authService: AuthService,
        private usersService: UserService
    ) {}

    checkIsAuthenticated(): Observable<boolean> {
        return this.authService.checkAuth().pipe(
            map((_) => true),
            catchError((_) => of(false))
        );
    }

    getCurrentUser(): Observable<UserDto> {
        return this.usersService
            .getCurrentUser()
            .pipe(tap((u) => (this._currentUser = u)));
    }

    reloadCurrentUser() {
        this.getCurrentUser().subscribe((_) => {});
    }

    get currentUser(): UserDto | undefined {
        return this._currentUser;
    }

    hasRole(name: string): boolean {
        return this.currentUser && this.currentUser.userRoles.includes(name);
    }

    getOrLoadCurrentUser(): Observable<UserDto> {
        if (this.currentUser) {
            return from([this.currentUser]);
        }

        return this.getCurrentUser();
    }

    logout() {
        window.location.href = logoutUrl;
    }

    login() {
        window.location.href = loginUrl;
    }
}
