import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Router } from '@angular/router';
import {InvoicesService} from "../../../core/api";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-approved-dialog',
  templateUrl: './approved-dialog.component.html',
  styleUrls: ['./approved-dialog.component.scss']
})
export class ApprovedDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ApprovedDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private invoiceService: InvoicesService,
              private toast: ToastService,
              private router: Router) {
  }

  ngOnInit(): void {
    console.log('ApproveDialogComponent::ngOnInit()');
    console.log(this.data)
  }

  onClickDecline(): void {
    console.log('ApproveDialogComponent::onClickDecline()');
    this.dialogRef.close(false)
  }

  onClickAccept(): void {
    console.log('ApproveDialogComponent::onClickAccept()');
    this.invoiceService
      .approveInvoice(this.data.invoice.id)
      .subscribe((res) => {
        console.log(res);
        this.dialogRef.close(true);
        this.toast.showInfoToast('Die Rechnung wurde akzeptiert!')
      }, err => {
        this.toast.showErrorToast(`${err.error.error}`, 5000);
      });
  }
}
