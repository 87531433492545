<div class="div-content">
  <ng-container *ngIf="authCheck">
    <div class="div-body">
      <div class="auth-check">
        <ng-container *ngIf="!error">
          <mat-spinner></mat-spinner>
        </ng-container>
        <ng-container *ngIf="error">
          <h1>Ein Fehler ist aufgetreten.</h1>
          <div>{{error}}</div>
          <button (click)="tryLoginAgain()" class="rb-button">Erneut anmelden</button>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!authCheck">
    <div class="div-header">
      <app-nav-bar></app-nav-bar>
    </div>
    <div class="div-body">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</div>
