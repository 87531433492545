import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, UserDto} from 'src/app/core/api';
import {SessionService} from 'src/app/core/services/session.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  constructor(
    private router: Router,
    private sessionService: SessionService
  ) {
  }

  ngOnInit(): void {
    console.log('NavBarComponent::ngOnInit()');
  }

  navigate(route: string): void {
    console.log('NavBarComponent::navigate()');
    this.router.navigate([route]);
  }

  getUserRoles(): string[] {
    if(!this.sessionService.currentUser) return [];
    return this.sessionService.currentUser.userRoles;
  }

  getApplicationRoles(): string[] {
    if(!this.sessionService.currentUser) return [];
    return this.sessionService.currentUser.applicationRoles;
  }

  logout() {
    this.sessionService.logout();
  }

  get currentUser(): UserDto | undefined {
    return this.sessionService.currentUser;
  }
  toggleMobileMenu(menu) {
    // menu.classList.toggle('open');

    var x = document.getElementById("hamburger-icon");
  if (x.className === "") {
    x.className = "open";
  } else {
    x.className = "";
  }
  }
  
  toggleNavBar(){}

}
