import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService, UserService } from 'src/app/core/api';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-deactivate-user-dialog',
    templateUrl: './deactivate-user-dialog.component.html',
    styleUrls: ['./deactivate-user-dialog.component.scss'],
})
export class DeactivateUserDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<DeactivateUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private adminService: AdminService,
        private toast: ToastService
    ) {}

    ngOnInit(): void {
        console.log('DeactivateUserDialogComponent::ngOnInit()');
    }

    get activate(): boolean {
        return !this.data.user.isApproved;
    }

    get username(): string {
        return this.data.user.username;
    }

    close() {
        this.dialogRef.close();
    }

    act() {
        this.adminService
            .setUserActive(this.data.user.id, !this.data.user.isApproved)
            .subscribe(
                (data) => {
                    this.toast.showInfoToast(
                        `Der Benutzer wurde erfolgreich ${
                            !this.data.user.isApproved
                                ? 'aktiviert!'
                                : 'deaktiviert!'
                        }`,
                        2000
                    );
                    this.data.user.isApproved = !this.data.user.isApproved;
                    this.dialogRef.close(this.data.user.isApproved);
                },
                (err) => {
                    this.toast.showErrorToast(
                        'Ein Fehler ist aufgetreten!',
                        2000
                    );
                }
            );
    }
}
