<div class="div-content">

  <div class="div-header">
    <label>Akzeptieren</label>
  </div>

  <div class="div-body">
    Soll die Rechnung akzeptiert werden?
  </div>

  <div class="div-footer">
    <button mat-raised-button color="primary" (click)="onClickAccept()">Akzeptieren</button>
    <button mat-button color="warn" (click)="onClickDecline()">Abbrechen</button>
  </div>
</div>
