<h1>Benutzer bearbeiten</h1>
<mat-divider></mat-divider>
<div class="div-content">
  <div class="div-left-side">

    <div class="div-user-information" [formGroup]="userForm">
      <mat-form-field appearance="fill">
        <mat-label>Benutzername</mat-label>
        <input matInput [readonly]="data.action === 'edit'" formControlName="username">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>E-Mail</mat-label>
        <input matInput [readonly]="data.action === 'edit'" formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Vorname</mat-label>
        <input matInput [readonly]="data.action === 'edit'" formControlName="forename">
      </mat-form-field>


      <mat-form-field appearance="fill">
        <mat-label>Nachname</mat-label>
        <input matInput [readonly]="data.action === 'edit'" formControlName="surname">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Department</mat-label>
        <input matInput [readonly]="true" formControlName="department">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Unterschriften Limit</mat-label>
        <input matInput type="number" formControlName="signingLimit">
      </mat-form-field>

    </div>
  </div>

  <div class="div-border"></div>

  <div class="div-right-side">
    <mat-form-field appearance="fill">
      <mat-label>Anwendungsrollen auswählen</mat-label>
      <mat-select multiple [formControl]="appRolesControl">
        <mat-option *ngFor="let role of appRoles" [value]="role.name">{{role.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Benutzerrollen auswählen</mat-label>
      <mat-select multiple [formControl]="userRolesControl">
        <mat-option *ngFor="let role of userRoles" [value]="role.name">{{role.name}}</mat-option>

      </mat-select>
    </mat-form-field>
  </div>

</div>

<mat-divider></mat-divider>

<div class="div-footer">
  <span></span>
  <button mat-raised-button color="primary" *ngIf="data.action === 'edit'" [disabled]="userForm.invalid"
    (click)="onClickEdit()">Speichern</button>
  <button mat-raised-button color="primary" *ngIf="data.action === 'add'" [disabled]="userForm.invalid"
    (click)="onClickAdd()">Hinzufügen</button>
  <button mat-button color="warn" (click)="onClickCancel()">Abbrechen</button>
</div>