import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { CheckedAndForwardDialogComponent } from './dialogs/checked-and-forward-dialog/checked-and-forward-dialog.component';
import { DeclineDialogComponent } from './dialogs/decline-dialog/decline-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { UserDialogComponent } from './dialogs/user-dialog/user-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { DeactivateUserDialogComponent } from './dialogs/deactivate-user-dialog/deactivate-user-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusPipe } from './pipes/status.pipe';
import { NotResponsibleDialogComponent } from './dialogs/not-responsible-dialog/not-responsible-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectApproverComponent } from './components/select-approver/select-approver.component';
import { ApprovedDialogComponent } from './dialogs/approved-dialog/approved-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ShowPdfDialogComponent } from './dialogs/show-pdf-dialog/show-pdf-dialog.component';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { IsoDateConverterPipe } from './pipes/iso-date-converter.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
    declarations: [
        NavBarComponent,
        CheckedAndForwardDialogComponent,
        DeclineDialogComponent,
        UserDialogComponent,
        DeactivateUserDialogComponent,
        StatusPipe,
        NotResponsibleDialogComponent,
        SelectApproverComponent,
        ApprovedDialogComponent,
        ShowPdfDialogComponent,
        UrlSanitizerPipe,
        IsoDateConverterPipe,
    ],
    exports: [
        NavBarComponent,
        StatusPipe,
        SelectApproverComponent,
        IsoDateConverterPipe,
        UrlSanitizerPipe,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatDividerModule,
        FormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatAutocompleteModule,
    ],
    providers: [
        FormBuilder,
        StatusPipe,
        IsoDateConverterPipe,
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        ,
    ],
})
export class SharedModule {}
