import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(
        private sessionService: SessionService,
        private toastService: ToastService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const requiredRoles = route.data.roles as Array<string>;
        const oneOf = route.data.oneOf as boolean;

        return this.sessionService.getOrLoadCurrentUser().pipe(
            map((currentUser) => {
                if (!currentUser) {
                    this.deny();
                }

                if (
                    !oneOf &&
                    !requiredRoles.every(
                        (role) =>
                            currentUser.applicationRoles.includes(role) ||
                            currentUser.userRoles.includes(role)
                    )
                ) {
                    this.deny();
                    return false;
                }

                if (
                    oneOf &&
                    !requiredRoles.find(
                        (role) =>
                            currentUser.applicationRoles.includes(role) ||
                            currentUser.userRoles.includes(role)
                    )
                ) {
                    this.deny();
                    return false;
                }

                return true;
            })
        );
    }

    deny() {
        this.toastService.showErrorToast('Keine Berechtigung.', 5000);
        this.router.navigate(['dashboard']);
    }
}
