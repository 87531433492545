<div class="div-content">

  <div class="div-header">
    <label>Geprüft und weiterleiten</label>
  </div>

  <div class="div-body">
    <app-select-approver (approver-selected)="approverSelected($event)" label="Zeichner">
    </app-select-approver>

  </div>

  <div class="div-footer">
    <button mat-raised-button color="primary" (click)="sign()" [disabled]="!canSubmit()">Freigabe</button>
    <button mat-button color="warn" (click)="close()">Abbrechen</button>
  </div>


</div>