<ng-container *ngIf="!loading">
    <mat-form-field [appearance]="appearance">
        <mat-label *ngIf="label && label.length>0">{{label}}</mat-label>
        <input type="text" tabindex="-1" [(ngModel)]="selectedApprover" matInput [matAutocomplete]="auto" (input)="onChange()" [formControl]="input">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayName"
            (optionSelected)="onSelectApprover($event)">
            <mat-option *ngFor="let approver of filteredOptions | async" [value]="approver">
                {{getDisplayName(approver)}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-container>
<ng-container *ngIf="loading">
    Lade Zeichner...
</ng-container>