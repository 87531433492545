<div class="div-content">

  <div class="div-header">
    <label>Ablehnen</label>
  </div>

  <div class="div-body">
    <mat-form-field appearance="outline">
      <mat-label>Grund</mat-label>
      <input matInput [(ngModel)]="reason">
    </mat-form-field>

  </div>

  <div class="div-footer">
    <button mat-raised-button color="primary" (click)="act()" [disabled]="!reason||reason.length===0">Ablehnen</button>
    <button mat-button color="warn" (click)="close()">Abbrechen</button>
  </div>
</div>