<div class="div-header-content">

  <!-- <img class="img-logo" (click)="navigate('dashboard')" src="/assets/images/rb_logo.png">
  <div class="div-toolbar-buttons" id="myTopnav">

    <button mat-button *ngIf="getApplicationRoles().includes('APP_UserAdmin')" (click)="navigate('admin')" color="accent">Admin</button>
    <button mat-button (click)="navigate('dashboard')" color="accent">Dashboard</button>
    <a href="https://asp.bmd.at/bmdweb2_216810/bmdweb2.dll/sso/login?sso_id=CB056768-3396-4356-81DC-66BFD56F65F1" target="_blank"><button mat-button color="accent">Time</button></a>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice')" color="accent">Invoice</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice-approved')" color="accent">Approved Inv.</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Manager')" (click)="navigate('invoice-management')" color="accent">Invoice Mgmt.</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice History')" (click)="navigate('invoice-history')" color="accent">Invoice History</button>
    <a href="javascript:void(0);" class="icon" (click)="toggleNavBar()">
      <i class="fa fa-bars"></i>
    </a>

  </div>
  <div class="div-user-information">
    <div *ngIf="currentUser" class="div-user-information-email" [matTooltip]="currentUser.email||'...'">
      {{currentUser.firstname}} {{currentUser.lastname}}
    </div>
    <div class="div-user-information-logout" (click)="logout()">
      Abmelden
    </div>
  </div> -->

  <!-- <div class="nav-bar-full">
    <img src="assets/images/rbsFavicon.png">
    <button mat-button *ngIf="getApplicationRoles().includes('APP_UserAdmin')" (click)="navigate('admin')" color="accent">Admin</button>
    <button mat-button (click)="navigate('dashboard')" color="accent">Dashboard</button>
    <a href="https://asp.bmd.at/bmdweb2_216810/bmdweb2.dll/sso/login?sso_id=CB056768-3396-4356-81DC-66BFD56F65F1" target="_blank"><button mat-button color="accent">Time</button></a>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice')" color="accent">Invoice</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice-approved')" color="accent">Approved Inv.</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('substitute-settings')" color="accent">Stellvertreter</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice Manager')" (click)="navigate('invoice-management')" color="accent">Invoice Mgmt.</button>
    <button mat-button *ngIf="getUserRoles().includes('Invoice History')" (click)="navigate('invoice-history')" color="accent">Invoice History</button>

    <div>
      <span *ngIf="currentUser" [matTooltip]="currentUser.email||'...'">{{currentUser.firstname}} {{currentUser.lastname}}</span>
      <button mat-button color="accent" (click)="logout()">Abmelden</button>
    </div>
  </div> -->

  <header class="header-full">
    <img class="img-logo" (click)="navigate('dashboard')" src="assets/images/rbsFavicon.png">
    <nav>
      <ul class="test">
        <li *ngIf="getApplicationRoles().includes('APP_UserAdmin')"><button mat-button  class="navbar-buttons" (click)="navigate('admin')" color="accent">Admin</button></li>
        <li><button mat-button (click)="navigate('dashboard')" class="navbar-buttons" color="accent">Dashboard</button></li>
        <li><a href="https://asp.bmd.at/bmdweb2_216810/bmdweb2.dll/sso/login?sso_id=CB056768-3396-4356-81DC-66BFD56F65F1" target="_blank"><button mat-button class="navbar-buttons" color="accent">Time</button></a></li>
        <li *ngIf="getUserRoles().includes('Invoice Approver')"><button mat-button  class="navbar-buttons" (click)="navigate('invoice')" color="accent">Invoice</button></li>
        <li *ngIf="getUserRoles().includes('Invoice Approver')"><button mat-button  class="navbar-buttons" (click)="navigate('invoice-approved')" color="accent">Approved Inv.</button></li>
        <li *ngIf="getUserRoles().includes('Invoice Approver')"><button mat-button  class="navbar-buttons" (click)="navigate('substitute-settings')" color="accent">Stellvertreter</button></li>
        <li *ngIf="getUserRoles().includes('Invoice Manager')"><button mat-button  class="navbar-buttons" (click)="navigate('invoice-management')" color="accent">Invoice Mgmt.</button></li>
        <li *ngIf="getUserRoles().includes('Invoice History')"><button mat-button  class="navbar-buttons" (click)="navigate('invoice-history')" color="accent">Invoice History</button></li>
      </ul>
    <div class="div-user-information">
      <!-- <span *ngIf="currentUser" [matTooltip]="currentUser.email||'...'">{{currentUser.firstname}} {{currentUser.lastname}}</span>
      <button mat-button color="accent" (click)="logout()">Abmelden</button> -->

      <div *ngIf="currentUser" class="div-user-information-email" [matTooltip]="currentUser.email||'...'">
        {{currentUser.firstname}} {{currentUser.lastname}} 
      </div>
      <div class="div-user-information-logout" (click)="logout()">
        Abmelden
      </div>
    </div>
  </nav>
  </header>

<!--
  <header class="header-full">
    <img class="img-logo" (click)="navigate('dashboard')" src="/assets/images/rbsFavicon.png">
    <nav>
      <ul>
        <li><a>
          <button mat-button *ngIf="getApplicationRoles().includes('APP_UserAdmin')" (click)="navigate('admin')"
                  color="accent">Admin
          </button>
        </a></li>
        <li>
          <button mat-button (click)="navigate('dashboard')" color="accent">
            Dashboard
          </button>
        </li>
        <li><a
          href="https://asp.bmd.at/bmdweb2_216810/bmdweb2.dll/sso/login?sso_id=CB056768-3396-4356-81DC-66BFD56F65F1"
          target="_blank">
          <button mat-button color="accent">Time</button>
        </a></li>
        <li>
          <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice')"
                  color="accent">Invoice
          </button>
        </li>
        <li>
          <button mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice-approved')"
                  color="accent">Approved Inv.
          </button>
        </li>
        <li>
          <button mat-button *ngIf="getUserRoles().includes('Invoice Manager')" (click)="navigate('invoice-management')"
                  color="accent">Invoice Mgmt.
          </button>
        </li>
        <li>
          <button mat-button *ngIf="getUserRoles().includes('Invoice History')" (click)="navigate('invoice-history')"
                  color="accent">Invoice History
          </button>
        </li>
      </ul>
      <div class="div-user-information">
        <div *ngIf="currentUser" class="div-user-information-email" [matTooltip]="currentUser.email||'...'">
          {{currentUser.firstname}} {{currentUser.lastname}}
        </div>
        <div class="div-user-information-logout" (click)="logout()">
          Abmelden
        </div>
      </div>
    </nav>
  </header>

-->

  <div id="hamburger-icon" (click)="toggleMobileMenu(this)">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
    <ul class="mobile-menu">
      <li><a mat-button *ngIf="getApplicationRoles().includes('APP_UserAdmin')" (click)="navigate('admin')"
             color="accent">Admin</a></li>
      <li><a mat-button (click)="navigate('dashboard')" color="accent">Dashboard</a></li>
      <li><a href="https://asp.bmd.at/bmdweb2_216810/bmdweb2.dll/sso/login?sso_id=CB056768-3396-4356-81DC-66BFD56F65F1"
             target="_blank">
        <button mat-button color="accent">Time</button>
      </a></li>
      <li><a mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice')"
             color="accent">Invoice</a></li>
      <li><a mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('invoice-approved')"
             color="accent">Approved Inv.</a></li>
      <li><a mat-button *ngIf="getUserRoles().includes('Invoice Approver')" (click)="navigate('substitute-settings')"
             color="accent">Stellvertreter</a></li>
      <li><a mat-button *ngIf="getUserRoles().includes('Invoice Manager')" (click)="navigate('invoice-management')"
             color="accent">Invoice Mgmt.</a></li>
      <li><a mat-button *ngIf="getUserRoles().includes('Invoice History')" (click)="navigate('invoice-history')"
             color="accent">Invoice History</a></li>
      <li>
        <div class="div-user-information">
          <div *ngIf="currentUser" class="div-user-information-email" [matTooltip]="currentUser.email||'...'">
            {{currentUser.firstname}} {{currentUser.lastname}}
          </div>
          <div class="div-user-information-logout" (click)="logout()">
            Abmelden
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
