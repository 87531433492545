import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {
  AdminService,
  ApproverDto,
  ApproversService,
  InvoiceDto,
  InvoicesService,
} from 'src/app/core/api';
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-checked-and-forward-dialog',
  templateUrl: './checked-and-forward-dialog.component.html',
  styleUrls: ['./checked-and-forward-dialog.component.scss'],
})
export class CheckedAndForwardDialogComponent {
  approver: ApproverDto | undefined;

  constructor(
    private dialogRef: MatDialogRef<CheckedAndForwardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoicesService,
    private toastService: ToastService,
    private router: Router,
  ) {
  }

  close() {
    this.dialogRef.close(false);
  }

  approverSelected(approver: ApproverDto) {
    this.approver = approver;
  }

  canSubmit() {
    return this.approver !== undefined;
  }

  sign() {
    if (!this.canSubmit()) {
      return;
    }
    this.invoiceService
      .approveAndForwardInvoice(this.data.invoice.id, {
        newApporverId: this.approver!.id!,
      })
      .subscribe((x) => {
          console.log(x);
          this.dialogRef.close(true);
          this.toastService.showInfoToast(`Rechnung wurde erfolgreich weitergeleitet!`, 5000);
        },
        err => {
          console.log(err)
          this.toastService.showErrorToast(`${err.error.error}`, 5000);
        });
  }
}
