<div class="div-content">

  <div class="div-header">
    <label>Nicht zuständig</label>
  </div>

  <div class="div-body">
    <app-select-approver (approver-selected)="approverSelected($event)" label="Neuer Zeichner">
    </app-select-approver>

  </div>

  <div class="div-footer">

        <button mat-raised-button color="primary" (click)="act()" [disabled]="!canSubmit()">Zuweisen</button>
        <button mat-raised-button color="primary" (click)="backToAccountingClicked()">Zurück an Accounting</button>
        <button mat-button color="warn" (click)="close()">Abbrechen</button>

    
    
    
  </div>
</div>