import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private sb: MatSnackBar) { }

  showInfoToast(message: string, milliseconds: number = 2000): void{
    this.sb.open(message, null, {panelClass: 'mat-info-snackbar', duration: milliseconds, verticalPosition: "bottom", horizontalPosition: "end"})
  }

  showErrorToast(message: string, milliseconds: number): void{
    this.sb.open(message, null, {panelClass: 'mat-error-snackbar', duration: milliseconds, verticalPosition: "bottom", horizontalPosition: "end"})
  }
}
