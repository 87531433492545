import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Event, NavigationStart, Router} from '@angular/router';
import { UserService } from './core/api';
import { SessionService } from './core/services/session.service';

const authErrors = {
    unknown_error_u: 'Unbekannter Fehler.',
    no_permission: 'Sie haben keine Berechtigung.',
    user_not_found: 'Ihr Benutzerkonto konnte nicht gefunden werden.',
    user_deactivated: 'Ihr Benutzerkonto wurde deaktiviert.',
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'rb-portal';

    authCheck = true;
    error = '';

    constructor(
        private sessionService: SessionService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        router.events.subscribe((event :Event) => {
            if (event instanceof NavigationStart) {
                if(!(event.url.includes('invoice-management') || event.url.includes('invoice-details'))) {
                    localStorage.removeItem('invoice-management-search-value');
                    localStorage.removeItem('invoice-management-range-start');
                    localStorage.removeItem('invoice-management-range-end');
                }

                if(!(event.url === '/invoice' || event.url.includes('invoice-details'))) {
                    localStorage.removeItem('invoice-search-value');
                }

                if(!(event.url === '/invoice-approved' || event.url.includes('invoice-details'))) {
                    localStorage.removeItem('invoice-approved-search-value');
                    localStorage.removeItem('invoice-approved-range-start');
                    localStorage.removeItem('invoice-approved-range-end');
                }

                if(!(event.url === '/invoice-history' || event.url.includes('invoice-details'))) {
                    localStorage.removeItem('invoice-history-search-value')
                    localStorage.removeItem('invoice-history-range-start');
                    localStorage.removeItem('invoice-history-range-end');
                }
            }
        });

    }

    ngOnInit(): void {
        this.runAuthCheck();
    }

    tryLoginAgain() {
        this.sessionService.login();
    }

    runAuthCheck() {
        this.sessionService
            .checkIsAuthenticated()
            .subscribe((authenticated) => {
                if (!authenticated) {
                    this.route.queryParamMap.subscribe((qp) => {
                        const error = qp.get('e');
                        if (error) {
                            this.error = authErrors[error];
                            if (!this.error) {
                                this.error = authErrors['unknown_error_u'];
                            }
                        } else {
                            this.sessionService.login();
                        }
                    });
                } else {
                    this.authCheck = false;
                    this.sessionService.reloadCurrentUser();
                }
            });
    }
}
