import {Component, Inject, OnInit, Sanitizer, SecurityContext} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InvoicesService} from "../../../core/api";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-show-pdf-dialog',
  templateUrl: './show-pdf-dialog.component.html',
  styleUrls: ['./show-pdf-dialog.component.scss']
})
export class ShowPdfDialogComponent implements OnInit {

  pdf: any;
  errorMessage: string;

  constructor(private dialogRef: MatDialogRef<ShowPdfDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private invoiceService: InvoicesService) {
  }

  ngOnInit(): void {
    this.loadPdf()
  }

  onClickClose(): void {
    this.dialogRef.close()
  }

  loadPdf(): void {
    console.log("YYYY");
    this.invoiceService.getPdf(this.data.invoice.id).subscribe(res => {
      this.convertBlobToBase64(res);
    }, err => {
      this.errorMessage = 'Es wurde keine Rechnung gefunden!'
    })
  }

  convertBlobToBase64(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      this.pdf = reader.result;
    }
  }
}
