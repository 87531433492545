import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ApproverDto, ApproversService } from 'src/app/core/api';
import { ApproversCacheService } from 'src/app/core/services/approvers-cache.service';

@Component({
    selector: 'app-select-approver',
    templateUrl: './select-approver.component.html',
    styleUrls: ['./select-approver.component.scss'],
})
export class SelectApproverComponent implements OnInit {
    @Input('approver') initialApprover: ApproverDto | undefined;
    approver: ApproverDto | undefined;
    @Input('appearance') appearance: MatFormFieldAppearance = 'fill';
    @Input('label') label = '';
    allApprovers: ApproverDto[] = [];
    selectedApprover: ApproverDto
    loading = true;

    @Output() approverChanged = new EventEmitter<object>();

    input = new FormControl();
    filteredOptions: Observable<ApproverDto[]>;

    @Output('approver-selected') approverSelected =
        new EventEmitter<ApproverDto>();

    constructor(private approverService: ApproversCacheService) {}

    ngOnInit(): void {
        this.filteredOptions = this.input.valueChanges.pipe(
            startWith(this.getCurrentName()),
            map((value) => this.filter(value + ''))
        );
        this.loadApprovers();
    }

    filter(value: string): ApproverDto[] {
        const val = value.toLowerCase();
        return this.allApprovers.filter((ap) =>
            this.getFilterName(ap).toLowerCase().includes(val)
        );
    }

    loadApprovers(): void {
        this.approverService.getApprovers().subscribe((res) => {
            this.loading = false;
            this.allApprovers = res;
            if (this.initialApprover) {
                this.setApprover(
                    this.allApprovers.find(
                        (a) => a.id === this.initialApprover.id
                    )
                );
            }
        });
        this.selectedApprover = this.initialApprover;
    }

    getDisplayName(approver: ApproverDto) {
        if (!approver) {
            return '';
        }
        if (
            approver.givenName &&
            approver.surname &&
            approver.givenName !== '' &&
            approver.surname !== ''
        ) {
            return (
                approver.givenName +
                ' ' +
                approver.surname +
                ' [' +
                approver.email +
                ']'
            );
        }
        return approver.email;
    }

    getFilterName(approver: ApproverDto): string {
        return approver
            ? approver.givenName + approver.surname + approver.email
            : '';
    }

    displayFullName(approver: ApproverDto): string {
        return approver && approver.givenName
            ? `${approver.givenName} ${approver.surname}`
            : '';
    }

    getCurrentName() {
        if (!this.approver) {
            return '';
        }
        return this.displayFullName(this.approver);
    }

    onChange() {
        this.setApprover(undefined);
        this.approverChanged.emit(this.selectedApprover);
    }

    setApprover(approver: ApproverDto, emit = false) {
        this.approver = approver;
        if (this.approver) {
            this.input.setValue(this.approver);
        }
        if (emit) {
            console.log('emit', this.approver);
            this.approverSelected.emit(this.approver);
        }
    }

    onSelectApprover(e: MatAutocompleteSelectedEvent): void {
        console.log('InvoiceManagementComponent::onSelectApprover()');
        this.setApprover(e.option.value, true);
    }
}
