import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from './core/guards/authentication.guard';
import {RoleGuard} from './core/guards/role.guard';
import {
  appUserAdmin as roleAppUserAdmin,
  userInvoiceApprover as roleUserInvoiceApprover,
  userInvoiceHistory as roleUserInvoiceHistory,
  userInvoiceManager as roleUserInvoiceManager,
} from './core/role_names';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('../app/pages/admin/admin.module').then(
        (m) => m.AdminModule
      ),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleAppUserAdmin]},
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../app/pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'invoice',
    loadChildren: () =>
      import('../app/pages/invoice/invoice.module').then(
        (m) => m.InvoiceModule
      ),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleUserInvoiceApprover]},
  },
  {
    path: 'invoice-approved',
    loadChildren: () =>
      import(
        '../app/pages/invoice-approved/invoice-approved.module'
        ).then((m) => m.InvoiceApprovedModule),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleUserInvoiceApprover]},
  },
  {
    path: 'substitute-settings',
    loadChildren: () =>
      import(
        '../app/pages/substitute-settings/substitute-settings.module'
        ).then((m) => m.SubstituteSettingsModule),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleUserInvoiceApprover]},
  },
  {
    path: 'invoice-history',
    loadChildren: () =>
      import('../app/pages/invoice-history/invoice-history.module').then(
        (m) => m.InvoiceHistoryModule
      ),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleUserInvoiceHistory]},
  },
  {
    path: 'invoice-management',
    loadChildren: () =>
      import(
        '../app/pages/invoice-management/invoice-management.module'
        ).then((m) => m.InvoiceManagementModule),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {roles: [roleUserInvoiceManager]},
  },
  {
    path: 'invoice-details',
    loadChildren: () =>
      import('../app/pages/invoice-details/invoice-details.module').then(
        (m) => m.InvoiceDetailsModule
      ),
    canActivate: [AuthenticationGuard, RoleGuard],
    data: {
      roles: [
        roleUserInvoiceHistory,
        roleUserInvoiceApprover,
        roleUserInvoiceManager,
      ],
      oneOf: true,
    },
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
