import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: unknown): any {
    //console.log('StatusPipe::transform()')
    switch(value)
    {
      case "SendBack": return "Nicht zuständig, noch nicht nach BMD exportiert";
      case "Pending": return "Zugewiesen";
      case "Exported": return "Nach BMD exportiert";
      case "Approved": return "Freigegeben, noch nicht nach BMD exportiert";
      case "Reject": return "Abgelehnt, noch nicht nach BMD exportiert";
    }
    return value;
  }

}
