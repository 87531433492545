/**
 * RbsPortalBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApproverDto } from './approverDto';
import { InvoiceStatusDto } from './invoiceStatusDto';


export interface InvoiceDto { 
    id?: number;
    bmdId?: string | null;
    companyNumber?: string | null;
    supplier?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string;
    amount?: number;
    currency?: string | null;
    currencyConversionRate?: number;
    readonly amountInEur?: number;
    this2ApproverNavigation?: ApproverDto;
    this2Approver?: number;
    status?: InvoiceStatusDto;
    comment?: string | null;
    pdfPath?: string | null;
}

