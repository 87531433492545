import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
    AdminService,
    RoleDto,
    UpdateUserModel,
    UserDto,
} from '../../../core/api';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
    currentUser!: UserDto;
    roles: RoleDto[] = [];

    userRolesControl = new FormControl();
    appRolesControl = new FormControl();

    userForm = this.fb.group({
        username: ['', Validators.required],
        email: ['', Validators.required],
        forename: ['', Validators.required],
        surname: ['', Validators.required],
        department: ['', Validators.required],
        signingLimit: ['', Validators.required],
    });

    constructor(
        private fb: FormBuilder,
        private adminService: AdminService,
        public dialogRef: MatDialogRef<UserDialogComponent>,
        private toast: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        console.log('UserDialogComponent::ngOnInit()');
        this.initializeCurrentUser();
        this.loadRoles();
    }

    loadRoles() {
        this.adminService.getRoles().subscribe((roles) => (this.roles = roles));
    }

    get appRoles(): RoleDto[] {
        return this.roles.filter((r) => r.name?.startsWith('APP_'));
    }

    get userRoles(): RoleDto[] {
        return this.roles.filter((r) => !r.name?.startsWith('APP_'));
    }

    initializeCurrentUser(): void {
        console.log('UserDialogComponent::initializeCurrentUser()');
        if (this.data.action === 'edit') {
            this.currentUser = this.data.user;
            this.userForm.get('username')?.setValue(this.currentUser.username);
            this.userForm.get('email')?.setValue(this.currentUser.email);
            this.userForm.get('forename')?.setValue(this.currentUser.firstname);
            this.userForm.get('surname')?.setValue(this.currentUser.lastname);
            this.userForm.get('username')?.disable();
            this.userForm.get('email')?.disable();
            this.userForm.get('forename')?.disable();
            this.userForm.get('surname')?.disable();
            this.userForm
                .get('department')
                ?.setValue(this.currentUser.department);
            this.userForm.get('department').disable();
            this.userForm
                .get('signingLimit')
                ?.setValue(this.currentUser.approvalLimit);

            this.userRolesControl.setValue(this.currentUser.userRoles);
            this.appRolesControl.setValue(this.currentUser.applicationRoles);
        }
    }

    onClickCancel(): void {
        console.log('UserDialogComponent::onClickCancel()');
        this.dialogRef.close('close');
    }

    onClickAdd(): void {
        console.log('UserDialogComponent::onClickAdd()');
        this.dialogRef.close('add');
        /*
    let createModel: CreateUserModel = {
      department: this.getFormValue('department'),
      approvalLimit: this.getFormValue('signingLimit'),
      email: this.getFormValue('email'),
      firstname: this.getFormValue('forename'),
      lastname: this.getFormValue('surname'),
      userName: this.getFormValue('username'),
      roles: this.selectedRoles,
    };*/

        /*this.adminService.createUser(createModel).subscribe((u) => {
      console.log(u);
      this.toast.showInfoToast('Der Benutzer wurde erfolgreich aktualisiert!', 2000)
      this.dialogRef.close();
    }, err => {
      console.log(err)
      this.toast.showErrorToast('Ein unbekannter Fehler ist aufgetreten', 2000);
    });*/
    }

    get selectedRoles(): string[] {
        const roles = [];
        roles.push(...(this.userRolesControl.value as string[]));
        roles.push(...(this.appRolesControl.value as string[]));
        return roles;
    }

    getFormValue(key: string): any {
        return this.userForm.get(key)?.value;
    }

    onClickEdit(): void {
        console.log(this.userRolesControl);
        console.log(this.appRolesControl);
        console.log('UserDialogComponent::onClickEdit()');
        console.log(this.selectedRoles);

        let userModel: UpdateUserModel = {
            department: this.getFormValue('department'),
            approvalLimit: this.getFormValue('signingLimit'),
            roles: this.selectedRoles,
        };

        this.adminService
            .updateUser(String(this.currentUser.id), userModel)
            .subscribe(
                (res) => {
                    console.log(res);
                    this.toast.showInfoToast(
                        'Der Benutzer wurde erfolgreich aktualisiert!',
                        2000
                    );
                    this.dialogRef.close('edit');
                },
                (error) => {
                    console.log(error);
                    this.toast.showErrorToast(
                        'Ein unbekannter Fehler ist aufgetreten',
                        2000
                    );
                }
            );
    }
}
