import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './core/api';
import { environment } from '../environments/environment';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        {
            provide: Configuration,
            useValue: new Configuration({
                basePath: environment.api,
                withCredentials: true,
            }),
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        PendingChangesGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
