import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";


@Pipe({
  name: 'isoDateConverter'
})
export class IsoDateConverterPipe implements PipeTransform {

  transform(value: any, format = 'DD.MM.YYYY'): string {
    //console.log('IsoDateConverterPipe::transform()')
    return moment(value).locale('de').format(format);
  }

}
