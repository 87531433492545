/**
 * RbsPortalBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvoiceStatusDto = 'Pending' | 'Approved' | 'Rejected' | 'SendBack' | 'Exported';

export const InvoiceStatusDto = {
    Pending: 'Pending' as InvoiceStatusDto,
    Approved: 'Approved' as InvoiceStatusDto,
    Rejected: 'Rejected' as InvoiceStatusDto,
    SendBack: 'SendBack' as InvoiceStatusDto,
    Exported: 'Exported' as InvoiceStatusDto
};

