import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {
  ApproverDto,
  ApproversService,
  InvoicesService,
} from 'src/app/core/api';
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-not-responsible-dialog',
  templateUrl: './not-responsible-dialog.component.html',
  styleUrls: ['./not-responsible-dialog.component.scss'],
})
export class NotResponsibleDialogComponent {
  approver: ApproverDto | undefined;

  constructor(
    private dialogRef: MatDialogRef<NotResponsibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoicesService,
    private toastService: ToastService,
    private router: Router,
  ) {
  }

  close() {
    this.dialogRef.close(false);
  }

  act() {
    this.invoiceService
      .notResponsibleForInvoice(this.data.invoice.id, {
        newApproverId: this.approver.id,
      })
      .subscribe((res) => {
          console.log(res);
          this.dialogRef.close(true);
          this.toastService.showInfoToast(`Rechnung wurde aktualisiert!`, 5000);
        },
        err => {
          this.toastService.showErrorToast(`${err.error.error}`, 5000);
        });
  }

  approverSelected(approver: ApproverDto) {
    this.approver = approver;
  }

  canSubmit() {
    return this.approver !== undefined;
  }
  
  backToAccountingClicked() {
      this.invoiceService
            .notResponsibleForInvoice(this.data.invoice.id, {
                newApproverId: this.data.invoice.this2Approver,
            })
            .subscribe(
                (data) => {
                    this.dialogRef.close(true);
                    this.toastService.showInfoToast('Erfolgreich zurückgeschickt.');
                    this.router.navigate([
                        'invoice'
                    ]);
                },
                (error) => {
                    this.dialogRef.close(true);
                    this.toastService.showErrorToast(
                        'Fehler beim Zurückschicken.',
                        3000
                    );
                }
            );
  }
}
