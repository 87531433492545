/**
 * RbsPortalBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApproverDto } from './approverDto';
import { HistoryItemDto } from './historyItemDto';


export interface UpdateInvoiceLineHistoryItemDto { 
    approver?: ApproverDto;
    oldOffsetAccount?: string | null;
    newOffsetAccount?: string | null;
    oldCostCenter?: string | null;
    newCostCenter?: string | null;
    oldCostUnit?: string | null;
    newCostUnit?: string | null;
    oldAmount?: number | null;
    newAmount?: number | null;
    id?: number | null;
    dateTime?: string;
    type?: string | null;
}

