/**
 * RbsPortalBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApproverDto } from './approverDto';
import { HistoryItemDto } from './historyItemDto';


export interface ApproveAndForwardInvoiceHistoryItemDto { 
    approvingApprover?: ApproverDto;
    approvalLimit?: number;
    forwardedApprover?: ApproverDto;
    comment?: string | null;
    id?: number | null;
    dateTime?: string;
    type?: string | null;
}

