import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApproverDto, ApproversService } from '../api';

@Injectable({
    providedIn: 'root',
})
export class ApproversCacheService {
    approvers: ApproverDto[];
    requesting = false;
    subject: Subject<ApproverDto[]> = new Subject<ApproverDto[]>();

    constructor(private approversService: ApproversService) {}

    getApprovers(): Observable<ApproverDto[]> {
        console.log('CHECKING');
        if (!this.approvers) {
            if (!this.requesting) {
                console.log('NO REQUEST');
                this.approversService
                    .getAllAprovers()
                    .pipe(
                        tap((a) => {
                            this.approvers = a;
                        })
                    )
                    .subscribe((a) => this.subject.next(a));
                this.requesting = true;
            }
            return this.subject.asObservable();
        }
        return from([this.approvers]);
    }
}
