import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {InvoicesService} from 'src/app/core/api';
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-decline-dialog',
  templateUrl: './decline-dialog.component.html',
  styleUrls: ['./decline-dialog.component.scss'],
})
export class DeclineDialogComponent implements OnInit {
  reason = '';

  constructor(
    private dialogRef: MatDialogRef<DeclineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoicesService,
    private toastService: ToastService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    console.log('DeclineDialogComponent::ngOnInit()');
  }

  close() {
    this.dialogRef.close(false);
  }

  act() {
    if (this.reason === '') {
      this.reason = 'kein Grund angegeben.';
    }

    this.invoiceService
      .rejectInvoice(this.data.invoice.id, {
        reason: this.reason,
      })
      .subscribe((res) => {
          console.log(res);
          this.dialogRef.close(true);
          this.toastService.showInfoToast(`Rechnung wurde aktualisiert!`, 5000);
        },
        err => {
          this.toastService.showErrorToast(`${err.error.error}`, 5000);
        });
  }
}
