export * from './admin.service';
import { AdminService } from './admin.service';
export * from './approvers.service';
import { ApproversService } from './approvers.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './invoices.service';
import { InvoicesService } from './invoices.service';
export * from './substitute.service';
import { SubstituteService } from './substitute.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AdminService, ApproversService, AuthService, InvoicesService, SubstituteService, UserService];
